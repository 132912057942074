import React from "react";
import { author, author__bioTwitter } from "./author.module.scss";
import { useStaticQuery, graphql } from "gatsby";

const Author = () => {
  const data = useStaticQuery(graphql`
    query AuthorQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  return (
    <div className={author}>
      <p>
        Written by <strong>{data.site.siteMetadata.author.name}</strong>
        {` `}
        <a
          className={author__bioTwitter}
          href={`https://twitter.com/${data.site.siteMetadata.social.twitter}`}
        >
          You should follow them on Twitter
        </a>
      </p>

      {/* <p>
        {data.site.siteMetadata.author.summary}
        <a
          className={author__bioTwitter}
          href={`https://www.twitter.com/${data.site.siteMetadata.social.twitter}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{data.site.siteMetadata.author.name}</strong> on Twitter
        </a>
      </p> */}
    </div>
  );
};

export default Author;
