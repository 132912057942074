import React from "react";
import { Link } from "gatsby";
import Author from "./author";
import Content from "./content";
import Meta from "./meta";
import Tags from "./tags";
import { post__homeButton, post__footer } from "./post.module.scss";

const Post = ({ html, title, date, tags, tagSlugs }) => {
  return (
    <article itemScope itemType="http://schema.org/Article">
      <header>
        <Link className={post__homeButton} to="/">
          All Articles
        </Link>
      </header>

      <section>
        <Content body={html} title={title} />
      </section>

      <footer className={post__footer}>
        <Meta date={date} />
        <Tags tags={tags} tagSlugs={tagSlugs} />
        <Author />
      </footer>
    </article>
  );
};

export default Post;
