import React from "react";
import { meta__date } from "./meta.module.scss";

const Meta = ({ date }) => (
  <div>
    <p className={meta__date}>Published {date}</p>
  </div>
);

export default Meta;
