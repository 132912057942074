import React from "react";
import { Link } from "gatsby";
import {
  tags,
  tags__list,
  tags__listItem,
  tags__listItemLink,
} from "./tags.module.scss";

const Tags = ({ tags: tagsData, tagSlugs }) => (
  <div className={tags}>
    <ul className={tags__list}>
      {tagSlugs &&
        tagSlugs.map((slug, i) => (
          <li className={tags__listItem} key={tagsData[i]}>
            <Link to={slug} className={tags__listItemLink}>
              {tagsData[i]}
            </Link>
          </li>
        ))}
    </ul>
  </div>
);

export default Tags;
