import React from "react";
import { content, content__title, content__body } from "./content.module.scss";

const Content = ({ body, title }) => (
  <div className={content}>
    <h1 className={content__title}>{title}</h1>
    <div className={content__body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
