import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SeoHelmet from "../components/seo-helmet";
import Post from "../components/post";

const BlogPostTemplate = ({ data }) => {
  const {
    markdownRemark: {
      html,
      fields: { tagSlugs },
      frontmatter: { title, date, description, tags },
    },
  } = data;

  return (
    <Layout>
      <SeoHelmet title={title} description={description} />
      <Post
        html={html}
        title={title}
        date={date}
        tags={tags}
        tagSlugs={tagSlugs}
      />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        tagSlugs
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`;
